
import './navbarhome.css';

import React from 'react';
import {Link} from 'gatsby';


function Navdownload(props) {

	return (
		<div>

		<nav className="navbar navbar-inverse navbar-fixed-top home-fixed-top" role="navigation">

        <div className="navbar-header">

            <div className="navbar-brand">
                <Link to='/' className="CB">TurtleClip</Link> 
            </div>

            <div className="home-list">
                <Link to='/pricing'>PRICING</Link>
            </div>

        </div>


        
</nav>


		</div>

		)
}


Navdownload.displayName = "Navdownload";
export default React.memo(Navdownload);
