// the setting bar towards left to change styling


import React from 'react';
// import Color from './colors';

import '../components/downopt.css';
// import Save from './saveState';
// import Save from './downloadvid';


import GetAppIcon from '@material-ui/icons/GetApp';

import {login_down, only_login} from '../components/firebase_login';
import {checkout, stripe_checkout_download} from '../components/striperedirect';



// to render watermark free video
// import {rendervid} from './rendervid';


import Navdownload from '../components/navdownload';

import axios from 'axios'; 
import {connect} from 'react-redux';


// add 'download' link as well + watermark and no watermark flow
// send video generate link with watermark yes or no option
// how to check if payment was successful ? 
// change payment success page to video output page! create another button for this

//  dont clear localstorage while login when on vidx page - subtitles havent been saved yet.
//  rather send localstorage to awsdynamoDB


class Renderdown extends React.Component {

constructor(props) {
    super(props);

      // debugger
      // var g = {}
      // g['subtitle'] = localStorage.getItem('x_subtitle')
      // g['heads'] = localStorage.getItem('x_heads')
      // g['sx'] = localStorage.getItem('x_sx')

    this.state =   {

      xout: props.xout,
      filename: props.filename,
      dvid: props.dvid,
      // urlsrt: props.urlsrt,

      // x: JSON.parse(props.ti),
      
      // vidsr_: props.vidsr_,
      // t: localStorage.getItem('t'),
      pre_sub: props.pre_sub,


      // duration: "33.9",
}

console.log('RENDERRRRDOWNNN', props)

this.downloadvideo = this.downloadvideo.bind(this);
this.removewatermark = this.removewatermark.bind(this);
this.downloadsrt = this.downloadsrt.bind(this)

}


downloadsrt(e, props) {

}

removewatermark(e, props) {

    e.preventDefault();
    e.stopPropagation();

  // show payment gateway, customer is already logged in + rendervid
  if (!localStorage.getItem('uid')) {

// login + payment
    login_down(e,props)

  }
  
  // if login + not subscribed,  stripe_checkout
  var sds = localStorage.getItem('cn') == "0" || !localStorage.getItem('subscribe')
  if (localStorage.getItem('uid') && sds) {
    // nav = <Navwork props={props} />
    // log = <a href={work} className="login_price">Try Now</a>;
    // sub = <form id='raz' />

// only payment
    // document.getElementsByClassName('PaymentButton')[0].click()

    document.getElementById("circleDown").style.display = "block"

    stripe_checkout_download(localStorage.getItem('uid'), localStorage.getItem('email'))

    // document.getElementById("circleDown").style.display = "none"

    // document.getElementsByName('email')[0].value = 'email'

    
  } 

// download the new video - without any watermark -- not here -- this should occur after payment success only!!!
// hence removing, it should be clicked on render.js componentDidMount
  // document.getElementById("save").click()

}



downloadvideo(e,props) {

    e.preventDefault();
    e.stopPropagation();


  var xout = localStorage.getItem('xout')

  var url = 'https://d2wxuz9jomxjzh.cloudfront.net/' + xout + '?cachebuster=' + Date.now();
//   // console.log('URL', url);


 //      // a.setAttribute('download', res.data)
      var a = document.createElement('a');
      // a.target = '_blank';
      a.href = url ;
      // a.style.display = 'none';
      // document.getElementById('subdiv').appendChild(a); // Required for Firefox
      a.click(); 







}




componentDidMount() {

  document.title = 'TurtleClip | Download Video';

  // document.getElementById('downsrt').style.display = 'none'
// set the bkgrnd color as default
 
  // const [target, setTarget] = useState(0); 
  // const [d, setD] = useState("s"); 
  // const [s, setS] = useState("false")

// /donwload/345234 url
  // localStorage.setItem('donwload_url', window.location.pathname)

	// useEffect(() => {

    var email = localStorage.getItem("email")

    // alert(email)

    // set value of subscribe in localstorage
    // if (localStorage.getItem('email')) {
    //     var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

    //     axios.get(suburl).then(response => {

    //       console.log('subscribe in Renderdown', response.data.Item)
    //       localStorage.setItem('cn', response.data.Item.count.S)
      
    //     // if the item doesnt exist in subscribe table
    //     }).catch(e => {
    //       localStorage.setItem('cn', '0')
    //     })

    //     console.log('subscribe in Renderdown', localStorage.getItem('cn'))
    // }

   // THIS IS NOT ROBUST !!

   // if user cancels, before she reloads, subscribe status would have been updated. Webhook + payment_id + timeout check after 1 sec on page load as well? 



    console.log('IN ELSE !!!')

// with watermark (or without) but before payment --- if page is reloaded, then? 
    // document.getElementById("downvid").src = this.state.dvid


  var xout = localStorage.getItem('xout')

  var url = 'https://d2wxuz9jomxjzh.cloudfront.net/' + xout + '?cachebuster=' + Date.now();
//   // console.log('URL', url);

  document.getElementById("downvid").src = url


	// },)



} // close componentDidMount



  // if(localStorage.getItem('subscribe') == "1") {
  //   // sub = <a href={work} className="login_price">My Videos</a>
  // }


render () {

return (
  <div>


  <Navdownload />




  <div id="downopt">

    <video id="downvid" controls />
    
    <div id="downoptbutton">


        <button id='downsign' onClick={(e) => this.downloadvideo(e, this.state)} type="button" className="btn btn-default" data-toggle="tab"> download video <GetAppIcon /> </button>


    </div>
  </div>

  </div>
)
}
}

        // <button id='downsrt' onClick={(e) => this.downloadsrt(e, this.state)} type="button" className="btn btn-default" data-toggle="tab" autoFocus> download srt </button>


const mapStateToProps = (state) => ({
  boxes2: state.boxes2,
  canvasValid: state.canvasValid,
  selectText: state.selectText,
  
  filename: state.filename,
  xout: state.xout,
  width: state.width,
  height: state.height,
  pc: state.pc,
  back_bar: state.back_bar, 
  front_bar: state.front_bar,
  bar_height: state.bar_height,
  duration: state.duration,
  dvid: state.dvid,
  pre_sub: state.pre_sub,
  ti: state.ti,
})

const mapDispatchToProps = dispatch => {
  return {

    increment: (box) => dispatch({ type: 'UPDATE_BOXES', payload: box}),
    update_x : (index, x, fill) => dispatch({ type: 'UPDATE_X', payload: {'index':index,'value':fill, 'x':x} }),
    edit_boxes : (index, value) => dispatch({ type: 'EDIT_BOXES', payload: {'index':index,'value':value} }),
    pop_boxes : (index) => dispatch({ type: 'POP_BOXES', payload: {'index':index} }),
    invalidate : (value) => dispatch({ type: 'INVALIDATE', payload: value}),
    selectedText : (value) => dispatch({ type: 'SELECTED_TEXT_BOX', payload: value}),
    setS : (value) => dispatch({ type: 'SET_S', payload: value}),
    render_x : (x, value) => dispatch({ type: 'RENDER_X', payload: {'x':x, 'value': value}}),
    decrement: () => dispatch({ type: 'DECREMENT' }),
    reset: () => dispatch({ type: 'RESET' })
  }
}


Renderdown.displayName = "Renderdown";
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Renderdown));
